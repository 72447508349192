import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import 'vuetify/src/styles/main.sass'

Vue.use(Vuetify);
export default new Vuetify({
  icons: {
    iconfont: 'mdi',
  },
});

// const vuetify = new Vuetify({
//   theme: {
//     themes: {
//       light: {
//         primary: '#ff005f',
//         secondary: '#b0bec5',
//         accent: '#82B1FF',
//         anchor: '#8c9eff',
//       },
//       dark: {
//         primary: '#ff005f',
//         secondary: '#b0bec5',
//         accent: '#82B1FF',
//         anchor: '#8c9eff',
//       },
//     },
//   },
// });

// export default vuetify;