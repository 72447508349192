const env = process.env.VUE_APP_ENV;

let envApiUrl = '';

if (env === 'production') {
  envApiUrl = `https://${process.env.VUE_APP_DOMAIN_PROD}`;
} else if (env === 'staging') {
  envApiUrl = `https://${process.env.VUE_APP_DOMAIN_STAG}`;
} else {
  envApiUrl = `http://${process.env.VUE_APP_DOMAIN_DEV}`;
}

// const envZoomApiKey = `${process.env.ZOOM_API_KEY}`;
const envZoomApiKey = 'qomVA6f9R4aRgrpbNuaKwg';

export const apiUrl = envApiUrl;
export const appName = process.env.VUE_APP_NAME;
export const zoomApiKey = envZoomApiKey;
