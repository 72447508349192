import axios from 'axios';
import { apiUrl } from '@/env';
import { IUserProfile, 
  IUserProfileUpdate, 
  IUserProfileCreate,
  IMeeting,
  IMeetingUpdate
} from './interfaces';

function authHeaders(token: string) {
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}

export const api = {
  async logInGetToken(username: string, password: string) {
    const params = new URLSearchParams();
    params.append('username', username);
    params.append('password', password);

    return axios.post(`${apiUrl}/api/v1/login/access-token`, params);
  },
  async getMe(token: string) {
    return axios.get<IUserProfile>(`${apiUrl}/api/v1/users/me`, authHeaders(token));
  },
  async updateMe(token: string, data: IUserProfileUpdate) {
    return axios.put<IUserProfile>(`${apiUrl}/api/v1/users/me`, data, authHeaders(token));
  },
  async getUsers(token: string) {
    return axios.get<IUserProfile[]>(`${apiUrl}/api/v1/users/`, authHeaders(token));
  },
  async updateUser(token: string, userId: number, data: IUserProfileUpdate) {
    return axios.put(`${apiUrl}/api/v1/users/${userId}`, data, authHeaders(token));
  },
  async createUser(token: string, data: IUserProfileCreate) {
    return axios.post(`${apiUrl}/api/v1/users/`, data, authHeaders(token));
  },
  async passwordRecovery(email: string) {
    return axios.post(`${apiUrl}/api/v1/password-recovery/${email}`);
  },
  async resetPassword(password: string, token: string) {
    return axios.post(`${apiUrl}/api/v1/reset-password/`, {
      new_password: password,
      token,
    });
  },
  async createMeeting(client: string, note: string) {
    return axios.post<IMeeting>(`${apiUrl}/api/v1/meetings/?client=${client}&note=${note}`);
  },
  async getAllMeetings(token: string, skip: number, limit: number) {
    return axios.get<IMeeting[]>(`${apiUrl}/api/v1/meetings/?skip=${skip}&limit=${limit}`, authHeaders(token));

  },
  async getMeeting(token: string, processing_id: string) {
    return axios.get<IMeeting>(`${apiUrl}/api/v1/meetings/${processing_id}`, authHeaders(token));
  },
  async updateMeeting(token: string, processing_id: string, data: IMeetingUpdate){
    return axios.put(`${apiUrl}/api/v1/meetings/${processing_id}`, data, authHeaders(token));
  },
  async genSignature(processing_id: string, role: number) {
    return axios.get(`${apiUrl}/api/v1/meetings/signature/${processing_id}/${role}`);
  }
};
