import '@babel/polyfill';
// Import Component hooks before component definitions
import './component-hooks';
import Vue from 'vue';
import '@mdi/font/css/materialdesignicons.css'
// import './plugins/vuetify';
import vuetify from './plugins/vuetify';
import './plugins/vee-validate';
import App from './App.vue';
import router from './router';
import store from '@/store';
import './registerServiceWorker';
import 'vuetify/dist/vuetify.min.css';

// to pypass the error
// Property 'ZoomMtg' does not exist on type 'Window'
declare global {
  interface Window { ZoomMtg: any; }
}

Vue.config.productionTip = false;
Vue.prototype.ZoomMtg = window.ZoomMtg;

new Vue({
  router,
  vuetify,
  store,
  render: (h) => h(App),
}).$mount('#app');
